/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	Spacer,
	Box,
	Heading,
	HStack,
	Icon,
	Text,
	VStack,
	Flex,
} from '@chakra-ui/react'
import { formatDistance } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { GoMention, GoGraph } from 'react-icons/go'
import { MdCenterFocusWeak, MdTimelapse, MdUpdate } from 'react-icons/md'
import Card from '../../../components/card'
import BroadCastStatic from '../../../components/broadCastStatic'
import { useAuthContext } from '../../../context/auth.context'
import { useLanguageContext } from '../../../context/locale.context'
import LeavesTable from '../../../components/LeavesTable'
import { useLoadActivityCounts } from '../../../hooks/shared.hooks'
import { getShortName, greeting } from '../../../utils/common.util'
import { useSharedContext } from '../../../context/shared.context'
import { sharedConst } from '../../../utils/action.constant'
import { getUTCtoIST } from '../../../utils/date.utils'
import { ACTION_PANEL_VIEW } from '../../action-panel/action-panel'
import {
	useAssetOverallGScore,
	useLoadLeaveMapping,
} from './../../../hooks/asset.hooks'
// import {
// 	dataLayerTagManager,
// 	sectionDetailedTrack,
// } from '../../repository/repo.utils'
// import { GA_CATEGORY_WORKSPACE_PANEL } from './../../repository/repo.utils'
import moment from 'moment'
import DashboardShortcutData from '../../dashboard/widget-workspace/dashboardShortcutData'

const OverviewPanel = ({ setToggleButton }) => {
	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const { dispatch } = useSharedContext()

	const [lastSeen, setlastSeen] = useState('')
	const [leaveData, setleaveData] = useState([])
	const { mutate, data } = useLoadActivityCounts()
	const { mutate: loadLeaveMappings } = useLoadLeaveMapping()
	const { mutate: mutateAssetOverallGScore, data: dataOverallGScore } =
		useAssetOverallGScore()
	const Stats = [
		{
			queue_name: 'Focus List',
			queue_id: 'MY_FOCUS',
			isStatic: true,
			icon: MdCenterFocusWeak,
			color: '#F7B017',
			flag: 1,
			isStatusFilter: 0,
			key: 'count_focus',
			title: locale['List that needs Immediate Focus'],
			isRefresh: true,
		},
		{
			queue_name: 'My updates',
			queue_id: 'UNREAD',
			isStatic: true,
			icon: MdUpdate,
			color: 'brand.800',
			flag: 11,
			isStatusFilter: 0,
			key: 'count_unread',
			title: locale['My Updates'],
			isRefresh: true,
		},
		{
			queue_name: 'Mentions',
			queue_id: 'MENTIONS',
			isStatic: true,
			icon: GoMention,
			color: 'orange.400',
			flag: 7,
			isStatusFilter: 0,
			key: 'count_mention',
			title: locale['Mentioned'],
			isRefresh: true,
		},
		{
			queue_name: 'Delayed', /// past due
			queue_id: 'DELAYED', /// past due
			isStatic: true,
			icon: MdTimelapse,
			color: 'red.500',
			flag: 6,
			isStatusFilter: 0,
			key: 'count_pastdue',
			title: locale['Your delayed list'],
			isRefresh: true,
		},
	]

	useEffect(() => {
		if (!!authData && authData?.asset_last_location_datetime) {
			let lastLogin = moment(authData?.asset_last_location_datetime)
				.format('YYYY-MM-DD HH:mm:ss')
				.split(' ')
				.join('T')
			let timeDist = formatDistance(new Date(lastLogin), new Date(), {
				roundingMethod: 'ceil',
			})
			console.log(lastLogin, 'lastLogin')
			setlastSeen(timeDist)
			mutate({
				flag: 0,
			})
		}
	}, [authData])

	useEffect(() => {
		mutateAssetOverallGScore({
			flag: 0,
			summary_id: 7,
		})
		loadLeavData()
	}, [])

	const loadLeavData = () => {
		loadLeaveMappings(
			{
				leave_type_id: 0,
				start_from: 0,
				limit_value: 20,
			},
			{
				onSuccess: async res => {
					setleaveData(res)
				},
				onError: err => {
					console.log({ err })
				},
			}
		)
	}

	let dashboardShortcuts = []
	if (authData?.organization_dashboard_shortcut_data) {
		dashboardShortcuts =
			typeof authData?.organization_dashboard_shortcut_data === 'string'
				? JSON.parse(authData.organization_dashboard_shortcut_data)
				: authData?.organization_dashboard_shortcut_data
	}

	return (
		<VStack w='full'>
			<Flex w='full'>
				<Card w='full' h='120px' boxShadow='md' mr={2}>
					<VStack alignItems='flex-start' p={4}>
						<Heading
							fontWeight={500}
							color='brand.900'
							fontSize={
								!!authData?.organization_welcome_message
									? 'lg'
									: { base: 'xl', sm: '3xl', md: '3xl' }
							}
							lineHeight={'110%'}
						>
							{locale[greeting()]}
							<Text as={'span'} color='orange.500' ml={1}>
								{!!getShortName(authData?.operating_asset_first_name)
									? getShortName(authData?.operating_asset_first_name).split(
											' '
									  )[0]
									: getShortName(authData?.operating_asset_first_name)}
							</Text>
						</Heading>
						{!!authData?.organization_welcome_message && (
							<Text
								fontSize={{ base: 'xl', sm: '2xl', md: '2xl' }}
								style={{ margin: '0px' }}
							>
								{authData?.organization_welcome_message}
							</Text>
						)}
						<Text textTransform='capitalize' style={{ margin: '0px' }}>
							{Object.keys(locale).length > 0 &&
								(/\d/.test(lastSeen)
									? `${locale['Your Last Login Was']} ${lastSeen} ${locale['ago']}`
									: `${locale['Your Last Login Was Less Than A ']} ${
											!!lastSeen.match('hour') ? 'n' : ''
									  } ${lastSeen.split(' ')[lastSeen.split(' ').length - 1]} ${
											locale['ago']
									  }`)}
						</Text>
					</VStack>
				</Card>
				<Spacer />
				{authData?.asset_type_flag_enable_gamification === 1 && (
					<Box minW='20%' borderRadius='md' boxShadow='md' bg='white' p={4}>
						<HStack alignItems='center' justifyContent='center'>
							<VStack justifyContent='center'>
								<Box
									rounded='md'
									size={{ base: '40px', md: 'md', lg: 'md' }}
									color='brand.800'
									bg='secondary'
									p={{ base: 2, sm: 2, md: 2, lg: 4 }}
								>
									<Icon as={GoGraph} h={10} w={10} />
								</Box>
							</VStack>
							<VStack
								alignItems='flex-end'
								justifyContent='space-around'
								w='full'
							>
								<Heading fontWeight={500} fontSize='3xl' color='brand.800'>
									{dataOverallGScore?.length &&
										`${dataOverallGScore[0]?.value} / ${dataOverallGScore[0]?.total_value} `}
								</Heading>
								<Text fontWeight={500} my={0} fontSize='md'>
									{locale['GScore']}
								</Text>
							</VStack>
						</HStack>
					</Box>
				)}
			</Flex>
			<HStack w='full' justifyContent='space-evenly'>
				{Stats.map(stat => {
					let hasCountKey = stat.hasOwnProperty('key')
					if (!!hasCountKey && data && !data[stat.key]) {
						return
					}
					return (
						<Box
							key={stat.key}
							minW='20%'
							flex={1}
							borderRadius='md'
							boxShadow='md'
							bg='white'
							p={4}
							cursor='pointer'
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_WORKSPACE_PANEL,
								// 	action: 'Overview Panel',
								// 	label: 'Set Selected Queue',
								// })
								// dataLayerTagManager('button_click', {
								// 	viewName: 'Home',
								// 	buttonName: stat.queue_name,
								// })
								dispatch({
									type: sharedConst.SET_SELECTED_FILTER,
									filter: {},
								})
								authData?.organization_flag_enable_collapsible === 1 &&
									setToggleButton(true)
								setTimeout(() => {
									dispatch({
										type: sharedConst.SET_SELECTED_QUEUE,
										queue: stat,
										isFirst: false,
									})
									dispatch({
										type: sharedConst.SET_SELECTED_FILTER,
										filter: {},
									})
									dispatch({
										type: sharedConst.SET_ACTION_PANEL_VIEW,
										view: ACTION_PANEL_VIEW.LIST_VIEW,
									})
								}, 1000)
							}}
						>
							<HStack alignItems='center' justifyContent='center'>
								<VStack justifyContent='center'>
									<Box
										rounded='md'
										size={{ base: '40px', md: 'md', lg: 'md' }}
										color={stat.color}
										bg='secondary'
										p={{ base: 2, sm: 2, md: 2, lg: 4 }}
									>
										<Icon as={stat.icon} h={10} w={10} />
									</Box>
								</VStack>
								<VStack
									alignItems='flex-end'
									justifyContent='space-around'
									w='full'
								>
									<Heading fontWeight={500} fontSize='3xl' color={stat.color}>
										{!!data ? data[stat.key] || 0 : 0}
									</Heading>
									<Text fontWeight={500} my={0} fontSize='md'>
										{locale[stat.queue_name]}
									</Text>
								</VStack>
							</HStack>
						</Box>
					)
				})}
			</HStack>

			{!!dashboardShortcuts.length && (
				<Box display='flex' w='100%'>
					<DashboardShortcutData data={dashboardShortcuts} />
					<BroadCastStatic />
				</Box>
			)}

			<HStack w='full' alignItems='flex-start' marginTop='20px'>
				{!!authData?.organization_flag_enable_leave && (
					<LeavesTable leaveData={leaveData} />
				)}
				<Spacer />
				{dashboardShortcuts.length === 0 && <BroadCastStatic />}
			</HStack>
		</VStack>
	)
}

export default OverviewPanel
